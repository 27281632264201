import { useCallback, useContext, useEffect, useState } from 'react';
import { searchUsers } from '../../utils/api';
import parseErrors from '../../utils/parseErrors';
import { MessageContext } from '../../context/messageContext';
import type { FilterValue, SortOrder, TablePaginationConfig } from 'antd/es/table/interface';
import { IPagination, IUser } from '../../types';

interface IUseUsersReturnType {
  users: IUser[];
  isLoading: boolean;
  pagination: IPagination;
  fetchUsers(): void;
  handleTableChange(tablePagination: TablePaginationConfig, _: Record<string, FilterValue | null>, sorter: any): void;
  setSearchTerm(term: string): void;
  searchTerm: string;
  handleResetSearch(): void;
}

const ROWS_PER_PAGE = 10;
const DEBOUNCE_TIMER = 350;

const defaultPagination: IPagination = {
  total: 0,
  currentPage: 1,
  offset: 0,
  rowsPerPage: ROWS_PER_PAGE,
};

const useUsers = (): IUseUsersReturnType => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [sortOrderColumns, setSortOrderColumns] = useState<Record<string, SortOrder>>({
    verified: null,
    hypelists: null,
    createdAt: 'descend',
  });
  const [searchTerm, setSearchTerm] = useState<string>(''); // Search term state
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(''); // Debounced term state

  const messageContext = useContext(MessageContext);

  // Debounce search term changes to avoid making requests on every keystroke
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, DEBOUNCE_TIMER);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await searchUsers(debouncedSearchTerm, pagination.offset, ROWS_PER_PAGE, sortOrderColumns);
      const { data: users, total } = data.data;

      setPagination((prev) => ({ ...prev, total }));
      setUsers(users);
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [debouncedSearchTerm, pagination.offset, sortOrderColumns, messageContext.instance]);

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    _: Record<string, FilterValue | null>,
    sorter: any,
  ) => {
    const sorterValue = sorter.order ?? null;
    const currentPage = tablePagination.current ?? 1;

    setSortOrderColumns({ [sorter.field]: sorterValue });
    setPagination((prev) => ({ ...prev, currentPage, offset: (currentPage - 1) * ROWS_PER_PAGE }));
  };

  // Trigger fetchUsers whenever debouncedSearchTerm, pagination, or sortOrder changes
  useEffect(() => {
    fetchUsers();
  }, [debouncedSearchTerm, pagination.offset, sortOrderColumns, fetchUsers]);

  const handleResetSearch = useCallback(() => {
    setSearchTerm(''); // Reset the search term
    setDebouncedSearchTerm(''); // Reset the debounced search term
    setPagination(defaultPagination);
    fetchUsers(); // Fetch users
  }, [fetchUsers]);

  return {
    users,
    isLoading,
    pagination,
    fetchUsers,
    handleTableChange,
    setSearchTerm,
    handleResetSearch,
    searchTerm,
  };
};

export default useUsers;
