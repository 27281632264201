import { Button, Form, Input, Row, Select, Tooltip } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { ChangeEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageContext } from '../../context/messageContext';
import { ICreateChatTemplate, EPromptCategory } from '../../types';
import parseErrors from '../../utils/parseErrors';
import { addChatTemplate } from '../../utils/api';

const { Option } = Select;

const CreateChatTemplates = () => {
  const navigate = useNavigate();
  const messageContext = useContext(MessageContext);
  const [templateData, setTemplateData] = useState<ICreateChatTemplate>({
    title: '',
    prompt: '',
    category: EPromptCategory.AICHATBOT,
    promptType: EPromptCategory.Places,
  });
  const [form] = Form.useForm();

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setTemplateData((data) => ({
      ...data,
      [name]: event.target.value,
    }));
  };

  const selectCategoryChange = (value: EPromptCategory) => {
    setTemplateData((data) => ({
      ...data,
      category: value,
    }));
  };

  const selectPromptTypeChange = (value: EPromptCategory) => {
    setTemplateData((data) => ({
      ...data,
      promptType: value,
    }));
  };

  const goBack = () => {
    navigate(-1);
  };

  const createAdmin = async () => {
    try {
      await addChatTemplate({
        ...templateData,
        promptType : templateData.category === EPromptCategory.AICHATBOT ? templateData.promptType : templateData.category
      });
      goBack();
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }
  };

  return (
    <div className="admin">
      <Row className="title">
        <Tooltip title="Back">
          <Button onClick={goBack} icon={<LeftOutlined />} />
        </Tooltip>

        <h1>Add Prompt Template</h1>
      </Row>

      <Row>
        <Form
          initialValues={templateData}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          name="control-hooks"
          onFinish={createAdmin}
        >
          <Form.Item name="title" label="Title">
            <Input value={templateData.title} onChange={onChange('title')} />
          </Form.Item>

          <Form.Item name="prompt" label="Prompt">
            <Input value={templateData.prompt} onChange={onChange('prompt')} />
          </Form.Item>

          <Form.Item name="cat" label="Category" rules={[{ required: false }]}>
            <Select
              placeholder="Select categoty if this prompt is for any particular hypelist type"
              onChange={selectCategoryChange}
              allowClear={false}
              defaultValue={templateData.category}
            >
              <Option value={EPromptCategory.AICHATBOT}>AI Chatbot</Option>
              <Option value={EPromptCategory.Places}>Places</Option>
              <Option value={EPromptCategory.Fitness}>Fitness</Option>
              <Option value={EPromptCategory.Movies}>Movies</Option>
              <Option value={EPromptCategory.Books}>Books</Option>
              <Option value={EPromptCategory.Products}>Products</Option>
              <Option value={EPromptCategory.Music}>Music</Option>
              <Option value={EPromptCategory.Links}>Links</Option>
              <Option value={EPromptCategory.Others}>Others</Option>
            </Select>
          </Form.Item>

          {templateData.category === EPromptCategory.AICHATBOT && <Form.Item name="pormptType" label="Prompt Type" rules={[{ required: false }]}>
            <Select
              placeholder="Select sub type of this prompt"
              onChange={selectPromptTypeChange}
              allowClear={false}
              defaultValue={templateData.promptType}
            >
              <Option value={EPromptCategory.Places}>Places</Option>
              <Option value={EPromptCategory.Fitness}>Fitness</Option>
              <Option value={EPromptCategory.Movies}>Movies</Option>
              <Option value={EPromptCategory.Books}>Books</Option>
              <Option value={EPromptCategory.Products}>Products</Option>
              <Option value={EPromptCategory.Music}>Music</Option>
              <Option value={EPromptCategory.Links}>Links</Option>
              <Option value={EPromptCategory.Others}>Others</Option>
            </Select>
          </Form.Item>}

          <Form.Item className="btn-container" wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </div>
  );
};

export default CreateChatTemplates;
