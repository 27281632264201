import { ColumnsType } from 'antd/es/table/interface';
import { IUser } from '../../types';
import { Link } from 'react-router-dom';
import CheckCircleTwoTone from '@ant-design/icons/lib/icons/CheckCircleTwoTone';

const columns: ColumnsType<IUser> = [
  {
    title: 'Username',
    key: 'username',
    render: (_: any, record: IUser) => <Link to={`/users/${record.id}`}>{record.username}</Link>,
  },
  {
    title: 'Verified',
    dataIndex: 'verified',
    key: 'verified',
    sorter: true,
    render: (_: any, record: IUser) => <span>{record.verified ? <CheckCircleTwoTone /> : ''}</span>,
  },
  {
    title: 'Name',
    dataIndex: 'displayName',
    key: 'displayName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Hypelists',
    dataIndex: 'hypelists',
    key: 'hypelists',
    sorter: true,
    defaultSortOrder: null,
  },
  {
    title: 'Referrals',
    dataIndex: 'referrals',
    key: 'referrals',
    sorter: true,
    defaultSortOrder: null,
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    defaultSortOrder: 'descend',
  },
];

export { columns };
