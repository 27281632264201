import { ReportState, ResourceType } from './utils/constants';

export interface ISignupData {
  username: string;
  password: string;
  email: string;
  phone: string;
  notifications: boolean;
}

export interface ISigninData {
  token: string;
}

export interface ISigninResponseData {
  token: string;
}

export interface IUser {
  bio: string | null;
  photoURL: string;
  id: string;
  token: string;
  email: string;
  invitation?: IInvitation[] | null;
  updatedAt: string;
  displayName: string;
  notifications: boolean;
  socialNetworks: {
    instagram: string;
    tiktok: string;
  };
  createdAt: string;
  username: string;
  hypelists?: number;
  hypelistURLs?: {
    Name: string;
    URL: string;
  }[];
  verified: boolean;
  brand: boolean;
  curator: boolean;
  referralCode: string;
  numberReferred: number;
}

export interface IInvitation {
  hypelist: string;
  sender: string;
  accepted: boolean;
}

export interface IHypelist {
  cover: {
    image: string;
  };
  createdAt: string;
  isPublic: boolean;
  id: string;
  userId: string;
  title: string;
  items: IHypelistItem[];
  shareLink: string;
  openAIStatus: string;
  updatedAt: string;
  user: IUser;
  status: Status;
  collaborators: IUser[];
}

export interface IHypelistAdmin {
  isPublic: boolean;
  id: string;
  userId: string;
  userName: string;
  title: string;
  shareLink: string;
  status: Status;
  createdAt: string;
  updatedAt: string;
}

export interface IHypelistItem {
  location: {
    name: string;
    address: string;
    lat: number;
    lng: number;
  };
  id: string;
  order: number;
  categoryId: {
    id: string;
    title: string;
    userId: string;
  };
  image: string;
  createdAt: string;
  title: string;
  subtitle: string;
  updatedAt: string;
}

export interface IAdmin {
  username: string;
  password: string;
  email: string;
  phone: string;
  notifications: boolean;
  id: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUpdateAdmin {
  username: string;
  currentPassword: string;
  newPassword: string;
  email: string;
  phone: string;
  notifications: boolean;
}

export interface IUpdateUser {
  verified: boolean;
  username?: string;
}

export interface IReportedListItem {
  reason: string;
  createdAt: string;
  id: string;
  userId: string;
  hypelistId: string;
  hypelistURL: string;
  updatedAt: string;
}

export interface IChatPrompts {
  id: number;
  uid: string;
  title: string;
  prompt: string;
  category: string;
  ordering: number;
  created_at: string;
  updated_at: string;
}

export interface IReport {
  uid: string;
  resource_uid: string;
  reporter_uid: string;
  reason: string;
  created_at: string;
  updated_at: string;
  status: ReportState;
}

export interface IReportedUser extends IReport {
  resource_type: ResourceType.USER;
}

export interface IReportedComment extends IReport {
  message: string;
  resource_type: ResourceType.COMMENT | ResourceType.REPLY;
}

export interface IUserInvitation {
  id: number;
  code: string;
  email: string;
  createdAt: string;
}

export interface IUserInvitationData {
  code: string;
}

export interface IUserExclusion {
  id: number;
  exclusion: string;
  reason: string;
  createdAt: string;
}

export interface IUserExclusionData {
  exclusion: string;
  reason: string;
}

export enum EPromptCategory {
  Places = 'places',
  Fitness = 'fitness',
  Movies = 'movies',
  Books = 'books',
  Products = 'products',
  Music = 'music',
  Links = 'links',
  Others = 'others',
  AICHATBOT = '',
}

export interface ICreateChatTemplate {
  title: string;
  prompt: string;
  category?: EPromptCategory;
  promptType?: EPromptCategory;
}

export interface ICuratedList {
  id: string; // maps to uid in DB
  title: string;
  subtitle: string;
  backgroundImageUrl: string;
  categoryId?: string;
  location?: Location;
  ordering: number;
  hypelists: IHypelistSnippet[];
  visible: boolean;
}

export interface IHighlight {
  id: string;
  backgroundImageUrl: string;
  createdAt: string;
  updatedAt: string;
  externalLinkTitle?: string;
  externalLinkUrl?: string;
  hypelist?: any;
  highlightTitle: string;
  highlightSubtitle?: string;
}

export interface IHypelistSnippet {
  id: string; // maps to uid in DB
  ordering: number;
  cover: {
    image?: string;
    color?: {
      hex1?: string;
      hex2?: string;
    };
  };
  type: string;
  isPublic: boolean;
  isSaved: boolean;
  shareLink: string;
  title: string;
  userInformation: {
    id: string; // maps to uid in DB
    photoURL: string;
    displayName: string;
    verified: boolean;
    username: string;
    email: string;
  };
  itemsLength: number;
  likes: number;
  collaborators: {
    id: string; // maps to uid in DB
    displayName: string;
    photoURL: string;
  }[];
}

interface Location {
  lat: number;
  lng: number;
}

export type Status = 'ok' | 'blocked' | 'reported';

export interface IFollowPeople {
  id: string;
  photoURL: string;
  username: string;
  displayName: string;
}

export interface IHypelistTemplate {
  id: string;
  backgroundImageUrl: string;
  backgroundColor: string;
  name: string;
  description: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface IPagination {
  total: number;
  currentPage: number;
  offset: number;
  rowsPerPage: number;
}
