import { Row as AntRow, Table, TableProps, Image, Button } from 'antd';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useContext, useEffect, useState } from 'react';
import { MessageContext } from '../../context/messageContext';
import { IHypelistTemplate } from '../../types';
import { createTemplate, deleteTemplate, getAllTemplates, reorderTemplates, uploadImage } from '../../utils/api';
import parseErrors from '../../utils/parseErrors';
import { useDispatch } from 'react-redux';
import React from 'react';
import { setLoader } from '../../store/actions/mainActions';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import CreateHypelistTemplate from './CreateHypelistTemplate';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners} />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const TemplatesTable = () => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState<IHypelistTemplate[]>([]);
  const messageContext = useContext(MessageContext);

  useEffect(() => {
    fetchTemplates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteTemplate = async (id: string) => {
    dispatch(setLoader(true));
    try {
      await deleteTemplate(id);
      await fetchTemplates();
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }
    dispatch(setLoader(false));
  };

  const handleCreateTemplate = async (payload: any) => {
    dispatch(setLoader(true));
    try {
      if (payload.file) {
        const response = await uploadImage(payload.file, 'HypelistTemplates');
        payload.backgroundImageUrl = response.data.data.imageUrl;
        delete payload.file;
      }

      await createTemplate(payload);
      await fetchTemplates();
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }
    dispatch(setLoader(false));
  };

  const fetchTemplates = async () => {
    dispatch(setLoader(true));

    try {
      const highlights = await getAllTemplates();
      setDataSource(highlights.data.data.data);
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        const updatedDataSource = arrayMove(previous, activeIndex, overIndex);
        updateOrdering(updatedDataSource.map((h) => h.id));
        return updatedDataSource;
      });
    }
  };

  const updateOrdering = async (orderingIds: string[]) => {
    dispatch(setLoader(true));
    await reorderTemplates(orderingIds);
    dispatch(setLoader(false));
  };

  const columns: TableProps<IHypelistTemplate>['columns'] = [
    {
      key: 'sort',
    },
    {
      title: 'Background Image',
      dataIndex: 'backgroundImageUrl',
      key: 'backgroundImageUrl',
      render: (backgroundImageUrl) => <Image width={65} src={backgroundImageUrl || ''} />,
    },
    {
      title: 'Background Color HEX',
      dataIndex: 'backgroundColor',
      key: 'backgroundColor',
      render: (color) => <div>{color}</div>,
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <div>{id}</div>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <div>{text}</div>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => <div>{text}</div>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text) => <div>{text}</div>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <AntRow>
          <Button type="primary" danger onClick={() => handleDeleteTemplate(record.id)}>
            <DeleteOutlined />
          </Button>
        </AntRow>
      ),
    },
  ];
  return (
    <>
      <AntRow>
        <h2>Hypelist Templates</h2>&nbsp;
        <CreateHypelistTemplate handleCreateTemplate={handleCreateTemplate} />
      </AntRow>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataSource.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey="id"
            components={{
              body: {
                row: Row,
              },
            }}
            columns={columns}
            dataSource={dataSource}
            size="small"
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

export default TemplatesTable;
