import useUsers from './useUsers';
import { Button, Input, Row, Space, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { columns } from './constants';

const Users = () => {
  const navigate = useNavigate();

  const { isLoading, users, pagination, handleTableChange, setSearchTerm, searchTerm, handleResetSearch } = useUsers();

  return (
    <div className="users">
      <Row>
        <h1>Users</h1>
      </Row>
      <Row>
        <Space direction="horizontal">
          <Input.Search
            size="large"
            placeholder="Search..."
            value={searchTerm}
            loading={isLoading}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term
          />
          <Button size="large" onClick={handleResetSearch}>
            Reset
          </Button>
        </Space>

        <Table
          onRow={({ id }) => {
            return {
              onClick: () => {
                navigate(`/users/${id}`);
              },
            };
          }}
          onChange={handleTableChange}
          rowKey="id"
          columns={columns}
          dataSource={users}
          pagination={{
            current: pagination.currentPage,
            total: pagination.total,
            showSizeChanger: false,
            position: ['bottomRight'],
          }}
          loading={isLoading}
        />
      </Row>
    </div>
  );
};

export default Users;
